//
.flex-start-start {
  @include flex(flex-start, flex-start);
}

.flex-start-center {
  @include flex(flex-start, center);
}

.flex-start-space {
  @include flex(flex-start, space-between);
}

.flex-start-end {
  @include flex(flex-start, flex-end);
}

//
.flex-center-start {
  @include flex(center, flex-start);
}

.flex-center-space {
  @include flex(center, space-between);
}

.flex-center-end {
  @include flex(center, flex-end);
}

//
.flex-end-start {
  @include flex(flex-end, flex-start);
}

.flex-end-space {
  @include flex(flex-end, space-between);
}

.flex-end-end {
  @include flex(flex-end, flex-end);
}

//
.flex-center-center {
  @include flex(center, center);
}

//
.flex-stretch-center {
  @include flex(stretch, center);
}
