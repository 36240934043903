#blogs-list-page {
  &.blogs-list-page--wrapper {
    // animation: appear 0.75s;
  }

  .blogs-list-page {
    &--inner {
      height: 100%;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;

      .blogs-list--wrapper {
        margin: 0 -8px;

        .blog-item--wrapper {
          padding: 0 8px;

          .blog-item--img {
            img {
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
