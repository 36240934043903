// @keyframes appear {
//   0% {
//     opacity: 0;
//     visibility: hidden;
//     transform: rotateX(-10deg);
//   }
//   100% {
//     opacity: 1;
//     visibility: visible;
//     transform: rotateX(0);
//   }
// }

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

@keyframes contact-button {
  0% {
    width: calc(100% - 0px - 0px);
    height: calc(100% - 0px - 0px);
  }
  50% {
    width: calc(100% - 3px - 3px);
    height: calc(100% - 3px - 3px);
  }
  100% {
    width: calc(100% - 0px - 0px);
    height: calc(100% - 0px - 0px);
  }
}

@keyframes logo-render {
  0% {
    fill: #fafdfd;
    stroke-dashoffset: 0;
    stroke-width: 30;
  }
  100% {
    fill: transparent;
    stroke-dashoffset: 1800;
    stroke-width: 3;
  }
}
