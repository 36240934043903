#user-manager-coin-page {
  &.user-manager-coin-page--wrapper {
    // animation: appear 0.75s;

    .header-child-without-coin--wrapper {
      h3 {
        text-transform: uppercase;
      }
    }

    .manager-coin-page--tab-nav {
      box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);
      padding: 8px;
      border-radius: 50px;
      overflow: hidden;
      padding: 0;

      .nav-link {
        border-radius: 50px;

        &.active {
          background-color: var(--color-brand-orange);
          color: var(--color-white);
        }
      }
    }

    .manager-coin-page--tab-content {
      & > * > * {
        animation: appear 0.75s;
      }

      .gift-tab--main {
        .list-wrapper {
          margin-top: 20px;
          padding-bottom: 20px;

          .item-wrapper {
            scroll-snap-align: start;
            box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);
            border-radius: 10px;
            overflow: hidden;
            position: relative;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .item-img {
              min-width: 110px;

              img {
                width: 110px;
                min-width: 110px;
                object-fit: cover;
                aspect-ratio: 1 / 1;
              }
            }
          }
        }
      }
    }
  }
}
