#notifications-page {
  &.notifications-page--wrapper {
    #header-child-without-coin {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .notifications-page--inner {
    .notifications-list--wrapper {
      max-height: calc(100vh - 50px - 42px - 24px);
      overflow-y: auto;
      scroll-snap-type: y mandatory;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .notification-item--wrapper {
        scroll-snap-align: start;
        transition: all 200ms linear;

        &:hover,
        &.unread {
          background-color: var(--color-pale-black);
        }

        .notification-item--link {
          padding: 16px 12px;
        }
      }
    }
  }
}
