.scroll-x {
  overflow-x: scroll;
}

.scroll-y {
  overflow-y: scroll;
}

.disabled {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}
