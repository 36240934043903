.referral-code-section--wrapper {
  background-color: var(--color-white);
  box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);
  border-radius: 50px;
  text-align: center;
  padding: 8px;
  padding-bottom: 10px;
  cursor: pointer;
  width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  p {
    line-height: 23px;
  }

  h3 {
    line-height: 30px;
  }
}
