#user-product-type-page {
  &.user-product-type-page--wrapper {
    // animation: appear 0.75s;
    position: relative;

    .product-type--img {
      img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .product-type-page--footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% + 12px + 12px);
      margin-left: -12px;
      padding: 10px 12px;
      border-top: 1px solid var(--color-light-black);
    }

    .product-list--wrapper {
      padding-top: 25px;
      padding-bottom: 20px;

      .product-list--inner {
        max-height: calc(
          100vh - 24px - 26px - 16px - 30vh - 8px - 26px - 65px - 25px - 20px
        );
        scroll-snap-type: y mandatory;
        overflow-y: scroll;
        &.full {
          max-height: calc(
            100vh - 24px - 26px - 16px - 30vh - 8px - 26px - 25px - 20px
          );
        }
        .product-item--wrapper {
          scroll-snap-align: start;

          &:not(:last-child) {
            .product-item--inner {
              margin-bottom: 20px;
            }
          }

          .product-item--inner {
            .btn-add-item {
              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
      }
    }
  }
}
