#addresses-manager-page {
  &.addresses-manager-page--wrapper {
    // animation: appear 0.75s;
    .screen-action-address--wrapper {
      // animation: appear 0.75s;

      .information-wrapper {
        & > * {
          input,
          textarea,
          select {
            &:focus,
            &:active {
              border-color: var(--color-light-black) !important;
            }
          }

          &:nth-child(2) {
            input {
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }
          }
          &:nth-child(3) {
            input {
              border-top-left-radius: 0 !important;
              border-top-right-radius: 0 !important;
              border-top: 0 !important;
            }
          }

          &:nth-child(5) {
            textarea {
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }
          }
          &:nth-child(6) {
            select {
              border-radius: 0 !important;
              border-top: 0 !important;
              border-bottom: 0 !important;
            }
          }
          &:nth-child(7) {
            & > * {
              &:nth-child(1) {
                select {
                  border-top-left-radius: 0 !important;
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  border-right: 0 !important;
                }
              }

              &:nth-child(2) {
                select {
                  border-bottom-left-radius: 0 !important;
                  border-top-left-radius: 0 !important;
                  border-top-right-radius: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .addresses-manager-page {
    &--inner {
      height: 100%;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;

      .address-item--wrapper {
        border-bottom: 1px solid var(--color-pale-black);

        span.status-apply {
          border: 1px solid var(--color-brand-orange);
          border-radius: 3px;
        }
      }
    }
  }
}
