#order-detail-page {
  &.order-detail-page--wrapper {
    // animation: appear 0.75s;
  }

  .order-detail-page {
    &--inner {
      height: 100%;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;

      .order-detail--list {
        border-radius: 5px;
        padding: 20px;
        box-shadow: 0 5px 10px var(--box-shadow-black);
      }

      .order-detail--extra {
        padding: 0 20px;
      }
    }
  }
}

.modal-order-detail {
  &.modal-reorder-order--wrapper {
    .order-detail--list {
      border-radius: 5px;
      padding: 20px;
      box-shadow: 0 5px 10px var(--box-shadow-black);
    }

    .order-detail--extra {
      padding: 0 20px;
    }

    .pyro {
      top: 0;
      bottom: unset;
    }

    .dropdown-select-voucher {
      .dropdown-toggle {
        padding: 0;
        border: 0;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        margin: 0;

        &:after {
          display: none;
        }

        span {
          white-space: break-spaces;
        }
      }

      .dropdown-menu {
        right: 0 !important;
        left: unset !important;
        width: 250px;
        max-height: 250px;
        overflow-y: auto;
        padding: 0;

        .dropdown-item {
          padding: 12px 16px;
          transition: all 200ms linear;
          white-space: break-spaces;

          &.active {
            background-color: var(--color-secondary-brand-blue);
            color: var(--color-white);
          }

          &:not(&.pointer-none),
          &:not(&.btn-exchange-voucher) {
            &:hover {
              background-color: var(--color-secondary-brand-blue);
              color: var(--color-white);
            }
          }

          &.pointer-none {
            pointer-events: none;
          }

          &.btn-exchange-voucher {
            background-color: var(--color-secondary-brand-blue);
            color: var(--color-white);

            &:hover {
              background-color: var(--color-brand-blue) !important;
            }
          }
        }
      }
    }
  }
}
