#user-orders-list-page {
  &.user-orders-list-page--wrapper {
    // animation: appear 0.75s;

    .orders-list-page--tab-nav {
      box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);
      padding: 8px;
      border-radius: 50px;
      overflow: hidden;
      padding: 0;

      .nav-link {
        border-radius: 50px;

        &.active {
          background-color: var(--color-brand-orange);
          color: var(--color-white);
        }
      }
    }

    .orders-list-page--tab-content {
      & > * > * {
        animation: appear 0.75s;
      }

      .orders-list-wrapper {
        margin-top: 20px;
        padding-bottom: 20px;
        max-height: calc(
          100vh - 24px - 26px - 16px - 42px - 20px - $footer-action-height
        );
        scroll-snap-type: y mandatory;
        overflow-y: scroll;
        margin-left: -12px;
        margin-right: -12px;
        padding-left: 12px;
        padding-right: 12px;

        .order-item {
          scroll-snap-align: start;
          box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);
          border-radius: 10px;
          overflow: hidden;
          position: relative;

          &:not(:last-child) {
            margin-bottom: 20px;
          }

          &--img {
            min-width: 110px;

            img {
              width: 110px;
              min-width: 110px;
              object-fit: cover;
              aspect-ratio: 1 / 1;
            }
          }

          .btn-coin {
            position: absolute;
            top: 8px;
            right: 8px;
            opacity: 0.6;
          }
        }
      }
    }
  }
}
