.contact-button {
  &--wrapper {
  }

  &--inner {
    box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);
    padding: 8px;
    border-radius: 50px;
    transition: all 200ms linear;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 100%;
    }

    button {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      padding: 0;
      background-color: transparent;
      border: 1px solid #56bdce;
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 3px - 3px);
        height: calc(100% - 3px - 3px);
        background-color: #56bdce;
        border-radius: 100%;
        z-index: 1;
        animation-name: contact-button;
        animation-duration: 800ms;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }

      svg {
        position: relative;
        z-index: 2;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    h4 {
      @media (max-width: 499px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
