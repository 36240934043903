#register-page {
  &.register-page--wrapper {
    overflow: hidden;
    position: relative;
  }

  .register-success-screen {
    &--wrapper {
      position: absolute;
      z-index: 999999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.5);

      &.show {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        transition: opacity 2000ms cubic-bezier(0.075, 0.82, 0.165, 1),
          visibility 2000ms cubic-bezier(0.075, 0.82, 0.165, 1),
          transform 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    &--inner {
      min-height: 100%;
      width: 100%;
      overflow: hidden;
      background-color: var(--color-white);
      padding: 90px 12px 80px;

      @include flex(center, center);
      flex-direction: column;
    }

    &--header {
      margin-bottom: 50px;
    }

    &--main {
      flex-grow: 1;
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .register-page {
    &--inner {
      position: relative;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      padding-top: 90px;
      padding-bottom: 80px;
      max-width: 300px;
      margin: 0 auto;
      // animation: appear 0.75s;

      &.hidden {
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
        opacity: 0;
        visibility: hidden;
        transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      .back-link {
        position: absolute;
        top: 20px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
        z-index: -1;

        &.show {
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
      }

      &.show-first-screen {
        .register-page--form__inner {
          transform: translateX(0);
        }

        .register-page--first-screen {
        }

        .register-page--second-screen {
          opacity: 0;
          visibility: hidden;
        }
      }

      &.show-second-screen {
        .register-page--form__inner {
          transform: translateX(calc(-300px - 50px));
        }

        .register-page--first-screen {
          opacity: 0;
          visibility: hidden;
        }

        .register-page--second-screen {
        }
      }

      .register-page--first-screen,
      .register-page--second-screen {
        transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    &--header {
      margin-bottom: 50px;

      img {
        margin: 0 auto;
      }
    }

    &--form {
      flex-grow: 1;

      &__inner {
        width: calc(600px + 50px);
        transition: all 800ms cubic-bezier(0.075, 0.82, 0.165, 1);

        @include flex();
        gap: 50px;

        & > * {
          width: 100%;
        }
      }
    }

    &--footer {
      margin-top: 50px;
    }
  }
}
