.mk-fz-56 {
  font-size: 56px;
  line-height: 62px;
}

.mk-fz-48 {
  font-size: 48px;
  line-height: 52px;
}

.mk-fz-32 {
  font-size: 32px;
  line-height: 38px;
}

.mk-fz-28 {
  font-size: 28px;
  line-height: 34px;
}

.mk-fz-24 {
  font-size: 24px;
  line-height: 30px;
}

.mk-fz-20 {
  font-size: 20px;
  line-height: 32px;
}

.mk-fz-18 {
  font-size: 18px;
  line-height: 24px;
}

.mk-fz-16 {
  font-size: 16px;
  line-height: 26px;
}

.mk-fz-14 {
  font-size: 14px;
  line-height: 20px;
}

.mk-fz-12 {
  font-size: 12px;
  line-height: 18px;
}

.mk-fz-10 {
  font-size: 10px;
  line-height: 14px;
}
