// #region : Fill
.fill-brand-blue * {
  fill: var(--color-brand-blue) !important;
}

.fill-secondary-brand-blue * {
  fill: var(--color-secondary-brand-blue) !important;
}

.fill-brand-orange * {
  fill: var(--color-brand-orange) !important;
}

.fill-green * {
  fill: var(--color-green) !important;
}

.fill-orange * {
  fill: var(--color-orange) !important;
}

.fill-blue * {
  fill: var(--color-blue) !important;
}

.fill-red * {
  fill: var(--color-red) !important;
}

.fill-white * {
  fill: var(--color-white) !important;
}

.fill-black * {
  fill: var(--color-black) !important;
}

.fill-secondary-black * {
  fill: var(--color-secondary-black) !important;
}

.fill-subtle-black * {
  fill: var(--color-subtle-black) !important;
}

.fill-light-black * {
  fill: var(--color-light-black) !important;
}

.hover-fill-brand-blue {
  &:hover * {
    fill: var(--color-brand-blue) !important;
  }
}

.hover-fill-secondary-brand-blue {
  &:hover * {
    fill: var(--color-secondary-brand-blue) !important;
  }
}

.hover-fill-brand-orange {
  &:hover * {
    fill: var(--color-brand-orange) !important;
  }
}

.hover-fill-green {
  &:hover * {
    fill: var(--color-green) !important;
  }
}

.hover-fill-orange {
  &:hover * {
    fill: var(--color-orange) !important;
  }
}

.hover-fill-blue {
  &:hover * {
    fill: var(--color-blue) !important;
  }
}

.hover-fill-red {
  &:hover * {
    fill: var(--color-red) !important;
  }
}

.hover-fill-white {
  &:hover * {
    fill: var(--color-white) !important;
  }
}

.hover-fill-black {
  &:hover * {
    fill: var(--color-black) !important;
  }
}

.hover-fill-secondary-black {
  &:hover * {
    fill: var(--color-secondary-black) !important;
  }
}

.hover-fill-subtle-black {
  &:hover * {
    fill: var(--color-subtle-black) !important;
  }
}

.hover-fill-light-black {
  &:hover * {
    fill: var(--color-light-black) !important;
  }
}
// #endregion
// #region : Stroke
.stroke-brand-blue * {
  stroke: var(--color-brand-blue) !important;
}

.stroke-secondary-brand-blue * {
  stroke: var(--color-secondary-brand-blue) !important;
}

.stroke-brand-orange * {
  stroke: var(--color-brand-orange) !important;
}

.stroke-green * {
  stroke: var(--color-green) !important;
}

.stroke-orange * {
  stroke: var(--color-orange) !important;
}

.stroke-blue * {
  stroke: var(--color-blue) !important;
}

.stroke-red * {
  stroke: var(--color-red) !important;
}

.stroke-white * {
  stroke: var(--color-white) !important;
}

.stroke-black * {
  stroke: var(--color-black) !important;
}

.stroke-secondary-black * {
  stroke: var(--color-secondary-black) !important;
}

.stroke-subtle-black * {
  stroke: var(--color-subtle-black) !important;
}

.stroke-light-black * {
  stroke: var(--color-light-black) !important;
}

.hover-stroke-brand-blue {
  &:hover * {
    stroke: var(--color-brand-blue) !important;
  }
}

.hover-stroke-secondary-brand-blue {
  &:hover * {
    stroke: var(--color-secondary-brand-blue) !important;
  }
}

.hover-stroke-brand-orange {
  &:hover * {
    stroke: var(--color-brand-orange) !important;
  }
}

.hover-stroke-green {
  &:hover * {
    stroke: var(--color-green) !important;
  }
}

.hover-stroke-orange {
  &:hover * {
    stroke: var(--color-orange) !important;
  }
}

.hover-stroke-blue {
  &:hover * {
    stroke: var(--color-blue) !important;
  }
}

.hover-stroke-red {
  &:hover * {
    stroke: var(--color-red) !important;
  }
}

.hover-stroke-white {
  &:hover * {
    stroke: var(--color-white) !important;
  }
}

.hover-stroke-black {
  &:hover * {
    stroke: var(--color-black) !important;
  }
}

.hover-stroke-secondary-black {
  &:hover * {
    stroke: var(--color-secondary-black) !important;
  }
}

.hover-stroke-subtle-black {
  &:hover * {
    stroke: var(--color-subtle-black) !important;
  }
}

.hover-stroke-light-black {
  &:hover * {
    stroke: var(--color-light-black) !important;
  }
}
// #endregion
