#profile-page {
  &.profile-page--wrapper {
    // animation: appear 0.75s;
  }

  .profile-page {
    &--inner {
      height: 100%;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;

      .box-content {
        background: rgb(56, 128, 187);
        background: linear-gradient(
          0deg,
          rgba(56, 128, 187, 1) 0%,
          rgba(8, 66, 140, 1) 70%
        );
        padding: 25px 20px;
        border-radius: 8px;

        .referral-code-section--wrapper {
          box-shadow: none;
          width: 100%;
        }
      }

      .profile-page--nav {
        padding: 0;
        list-style: none;
      }
    }
  }
}
