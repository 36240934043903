// #region : Brand
.mk-text-brand-blue {
  color: var(--color-brand-blue);
}

.mk-text-secondary-brand-blue {
  color: var(--color-secondary-brand-blue);
}

.mk-text-brand-orange {
  color: var(--color-brand-orange);
}

.mk-text-hover-brand-blue {
  &:hover {
    color: var(--color-brand-blue);
  }
}

.mk-text-hover-secondary-brand-blue {
  &:hover {
    color: var(--color-secondary-brand-blue);
  }
}

.mk-text-hover-brand-orange {
  &:hover {
    color: var(--color-brand-orange);
  }
}
// #endregion
// #region : Green
.mk-text-green {
  color: var(--color-green);
}

.mk-text-secondary-green {
  color: var(--color-secondary-green);
}

.mk-text-subtle-green {
  color: var(--color-subtle-green);
}

.mk-text-light-green {
  color: var(--color-light-green);
}

.mk-text-pale-green {
  color: var(--color-pale-green);
}

.mk-text-hover-green {
  &:hover {
    color: var(--color-green);
  }
}

.mk-text-hover-secondary-green {
  &:hover {
    color: var(--color-secondary-green);
  }
}

.mk-text-hover-subtle-green {
  &:hover {
    color: var(--color-subtle-green);
  }
}

.mk-text-hover-light-green {
  &:hover {
    color: var(--color-light-green);
  }
}

.mk-text-hover-pale-green {
  &:hover {
    color: var(--color-pale-green);
  }
}
// #endregion
// #region : Orange
.mk-text-orange {
  color: var(--color-orange);
}

.mk-text-secondary-orange {
  color: var(--color-secondary-orange);
}

.mk-text-subtle-orange {
  color: var(--color-subtle-orange);
}

.mk-text-light-orange {
  color: var(--color-light-orange);
}

.mk-text-pale-orange {
  color: var(--color-pale-orange);
}

.mk-text-hover-orange {
  &:hover {
    color: var(--color-orange);
  }
}

.mk-text-hover-secondary-orange {
  &:hover {
    color: var(--color-secondary-orange);
  }
}

.mk-text-hover-subtle-orange {
  &:hover {
    color: var(--color-subtle-orange);
  }
}

.mk-text-hover-light-orange {
  &:hover {
    color: var(--color-light-orange);
  }
}

.mk-text-hover-pale-orange {
  &:hover {
    color: var(--color-pale-orange);
  }
}
// #endregion
// #region : Blue
.mk-text-blue {
  color: var(--color-blue);
}

.mk-text-secondary-blue {
  color: var(--color-secondary-blue);
}

.mk-text-subtle-blue {
  color: var(--color-subtle-blue);
}

.mk-text-light-blue {
  color: var(--color-light-blue);
}

.mk-text-pale-blue {
  color: var(--color-pale-blue);
}

.mk-text-hover-blue {
  &:hover {
    color: var(--color-blue);
  }
}

.mk-text-hover-secondary-blue {
  &:hover {
    color: var(--color-secondary-blue);
  }
}

.mk-text-hover-subtle-blue {
  &:hover {
    color: var(--color-subtle-blue);
  }
}

.mk-text-hover-light-blue {
  &:hover {
    color: var(--color-light-blue);
  }
}

.mk-text-hover-pale-blue {
  &:hover {
    color: var(--color-pale-blue);
  }
}
// #endregion
// #region : Red
.mk-text-red {
  color: var(--color-red);
}

.mk-text-secondary-red {
  color: var(--color-secondary-red);
}

.mk-text-subtle-red {
  color: var(--color-subtle-red);
}

.mk-text-light-red {
  color: var(--color-light-red);
}

.mk-text-pale-red {
  color: var(--color-pale-red);
}

.mk-text-hover-red {
  &:hover {
    color: var(--color-red);
  }
}

.mk-text-hover-secondary-red {
  &:hover {
    color: var(--color-secondary-red);
  }
}

.mk-text-hover-subtle-red {
  &:hover {
    color: var(--color-subtle-red);
  }
}

.mk-text-hover-light-red {
  &:hover {
    color: var(--color-light-red);
  }
}

.mk-text-hover-pale-red {
  &:hover {
    color: var(--color-pale-red);
  }
}
// #endregion
// #region : White
.mk-text-white {
  color: var(--color-white);
}

.mk-text-hover-white {
  &:hover {
    color: var(--color-white);
  }
}
// #endregion
// #region : Black
.mk-text-black {
  color: var(--color-black);
}

.mk-text-secondary-black {
  color: var(--color-secondary-black);
}

.mk-text-subtle-black {
  color: var(--color-subtle-black);
}

.mk-text-light-black {
  color: var(--color-light-black);
}

.mk-text-pale-black {
  color: var(--color-pale-black);
}

.mk-text-hover-black {
  &:hover {
    color: var(--color-black);
  }
}

.mk-text-hover-secondary-black {
  &:hover {
    color: var(--color-secondary-black);
  }
}

.mk-text-hover-subtle-black {
  &:hover {
    color: var(--color-subtle-black);
  }
}

.mk-text-hover-light-black {
  &:hover {
    color: var(--color-light-black);
  }
}

.mk-text-hover-pale-black {
  &:hover {
    color: var(--color-pale-black);
  }
}
// #endregion
