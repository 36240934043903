// :root {
//   --color-darkest: #f89d26;
//   --color-dark: #f9cd92;
//   --color-medium: #f5c480;
//   --color-light: #fdeedb;
//   --animation-speed: 8s;
//   --animation-loop: infinite;
// }

#loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: var(--color-white);

  .loading-inner {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }

  .loading-progress {
    height: 2px;
    width: 190px;
    position: relative;
    transition: all 0.5s ease-out;
    background-color: var(--color-subtle-black);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 20px;
      background-color: var(--color-brand-blue);
      transition: all 0.5s ease-out;
      animation: loading 3s ease-in-out infinite;
    }

    @keyframes loading {
      from {
        width: 0;
        margin-left: 0;
      }

      50% {
        width: 100%;
        margin-left: 0;
      }

      to {
        width: 0;
        margin-left: 100%;
      }
    }
  }

  svg {
    width: 120%;
    stroke: #fafdfd;
    stroke-width: 30;
    fill: #fafdfd;
    stroke-dasharray: 1800;
    stroke-dashoffset: 0;
    animation: logo-render 2s ease infinite;
  }

  .loader {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .loader__bar {
    display: flex;
    flex: 1;
    position: relative;
    width: 100%;
  }

  .loader__bar:before {
    animation: loader ease var(--animation-speed) var(--animation-loop);
    animation-delay: 100ms;
    background: var(--color-darkest);
    background-size: 200% 200%;
    content: "";
    height: 100%;
    width: 0%;
  }

  .loader__bar:after {
    animation: loaderAlt ease var(--animation-speed) var(--animation-loop);
    animation-delay: 100ms;
    background: var(--color-darkest);
    background-size: 200% 200%;
    content: "";
    height: 100%;
    width: 100%;
  }

  .loader__bar--delay-1:before,
  .loader__bar--delay-1:after {
    animation-delay: 200ms;
  }

  .loader__bar--delay-2:before,
  .loader__bar--delay-2:after {
    animation-delay: 300ms;
  }

  .loader__bar--delay-3:before,
  .loader__bar--delay-3:after {
    animation-delay: 400ms;
  }

  .loader__bar--delay-4:before,
  .loader__bar--delay-4:after {
    animation-delay: 500ms;
  }

  .loader__bar--delay-5:before,
  .loader__bar--delay-5:after {
    animation-delay: 600ms;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: brightness(0) invert(1);
  }
}

// @keyframes loader {
//   0% {
//     background: var(--color-dark);
//     width: 0%;
//   }
//   20% {
//     width: 100%;
//   }
//   39% {
//     background: var(--color-dark);
//   }
//   40% {
//     background: var(--color-light);
//     width: 0%;
//   }
//   60% {
//     width: 100%;
//   }
//   80% {
//     width: 0%;
//   }
//   100% {
//     background: var(--color-light);
//     width: 0%;
//   }
// }

// @keyframes loaderAlt {
//   0% {
//     background: var(--color-darkest);
//     width: 100%;
//   }
//   19% {
//     background: var(--color-darkest);
//   }
//   20% {
//     background: var(--color-medium);
//     width: 0%;
//   }
//   40% {
//     width: 100%;
//   }
//   59% {
//     background: var(--color-medium);
//   }
//   60% {
//     background: var(--color-darkest);
//     width: 0;
//   }
//   80% {
//     width: 100%;
//   }
//   100% {
//     background: var(--color-darkest);
//     width: 100%;
//   }
// }
