#header-child-with-coin {
  &.header-child-with-coin--wrapper {
    background-color: var(--color-white);
  }
}

#header-child-without-coin {
  &.header-child-without-coin--wrapper {
    background-color: var(--color-white);
  }
}

#header-welcome {
  &.header-welcome--wrapper {
    background-color: var(--color-white);
  }
}
