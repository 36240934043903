#gifts-list-page {
  &.gifts-list-page--wrapper {
    // animation: appear 0.75s;
  }

  .gifts-list-page {
    &--inner {
      height: 100%;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;

      .gifts-list--wrapper {
        margin: 0 -8px;

        .gift-item--wrapper {
          padding: 0 8px;

          .gift-item--img {
            img {
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
