.modal-add-product--wrapper {
  .modal-dialog {
    margin-top: 50px;

    .input-number-custom--wrapper {
      border-radius: 40px;
      height: 40px;
    }

    .input-custom {
      input {
        height: 40px !important;
        padding-left: 15px !important;
        padding-right: 10px !important;
        border-radius: 40px !important;
        margin: 0;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .btn-remove-input {
      background-color: transparent !important;
      border: 0 !important;
    }

    .btn-add-quantity-input {
      &.btn-custom__disabled {
        color: var(--color-secondary-black) !important;
        background-color: var(--color-light-black) !important;
      }
    }

    .dropdown-input-type {
      .dropdown-toggle {
        margin: 0;
        padding: 0;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        border: 1px solid var(--color-light-black);
        border-left: 0;
        width: 90px;
        height: 40px;
        border-radius: 40px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:after {
          display: none;
        }
      }

      .dropdown-menu {
        padding: 0;

        .dropdown-item {
          padding: 8px 12px;
          transition: all 200ms linear;

          &:hover {
            background-color: var(--color-secondary-brand-blue);
            color: var(--color-white);
          }
        }
      }
    }
  }

  &.modal-option {
    z-index: 1057;
  }

  & ~ .modal-backdrop {
    z-index: 1056;
  }
}
