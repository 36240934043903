.auth-layout {
  .input-custom {
    input,
    select {
      border: 0 !important;
      box-shadow: var(--box-shadow-input-black) !important;

      &.is-valid {
        box-shadow: var(--box-shadow-input-green) !important;
      }

      &.is-invalid {
        box-shadow: var(--box-shadow-input-red) !important;
      }

      &:focus {
        box-shadow: var(--box-shadow-input-brand) !important;
      }
    }
  }
}
