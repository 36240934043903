#user-home-page {
  &.user-home-page--wrapper {
    // animation: appear 0.75s;

    section {
      .section-title {
        font-size: 14px;
        line-height: 22px;
        color: var(--color-brand-blue);
        font-weight: 600;
        margin-bottom: 0;
      }

      .section-sub-title {
        font-size: 14px;
        line-height: 22px;
        color: var(--color-subtle-black);
        font-weight: 400;
        font-style: italic;
        cursor: pointer;
        margin-bottom: 0;
        opacity: 0.8;
        transition: all 200ms linear;

        &:hover {
          opacity: 1;
          color: var(--color-secondary-brand-blue);
        }
      }
    }

    .home-page--recent-orders {
      .recent-orders--inner {
        .orders-list {
          display: flex;
          scroll-snap-type: x mandatory;
          overflow-x: scroll;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .order-item {
            scroll-snap-align: start;

            a {
              &:hover > * {
                color: var(--color-secondary-brand-blue);
              }
            }

            p {
              transition: all 200ms linear;
            }

            &:first-child {
              p {
                color: var(--color-secondary-brand-blue);
              }
            }
          }
        }
      }
    }

    .home-page--trending-products {
      .trending-products--inner {
        .products-list {
          display: flex;
          scroll-snap-type: x mandatory;
          overflow-x: scroll;
          margin: 0 -8px;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .product-item {
            scroll-snap-align: start;
            width: calc(100% / 3);
            max-width: calc(100% / 3);
            min-width: calc(100% / 3);
            padding: 0 8px;

            img {
              object-fit: cover;
              width: 100%;
              aspect-ratio: 1 / 1;
              border-radius: 8px;
            }

            p {
              transition: all 200ms linear;
            }
          }
        }
      }
    }

    .home-page--categories-list {
      .categories-list--inner {
        overflow: hidden;

        .categories-list {
          display: flex;
          scroll-snap-type: x mandatory;
          overflow-x: scroll;
          margin: 0 -8px;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .category-item {
            scroll-snap-align: start;
            width: calc(100% / 1.5);
            max-width: calc(100% / 1.5);
            min-width: calc(100% / 1.5);
            padding: 0 8px;

            img {
              object-fit: cover;
              width: 100%;
              aspect-ratio: 1 / 1;
              border-radius: 8px;
            }
          }
        }
      }
    }

    .home-page--vouchers-list {
      .vouchers-list--inner {
        overflow: hidden;

        .vouchers-list {
          display: flex;
          scroll-snap-type: x mandatory;
          overflow-x: scroll;
          margin: 0 -8px;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .voucher-item {
            scroll-snap-align: start;
            width: calc(100% / 2.2);
            max-width: calc(100% / 2.2);
            min-width: calc(100% / 2.2);
            padding: 0 8px;

            img {
              object-fit: cover;
              width: 100%;
              aspect-ratio: 1 / 1;
              border-radius: 8px;
            }
          }
        }
      }
    }

    .home-page--gifts-list {
      .gifts-list--inner {
        overflow: hidden;

        .gifts-list {
          display: flex;
          scroll-snap-type: x mandatory;
          overflow-x: scroll;
          margin: 0 -8px;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .gift-item {
            scroll-snap-align: start;
            width: calc(100% / 2.2);
            max-width: calc(100% / 2.2);
            min-width: calc(100% / 2.2);
            padding: 0 8px;

            img {
              object-fit: cover;
              width: 100%;
              aspect-ratio: 1 / 1;
              border-radius: 8px;
            }
          }
        }
      }
    }

    .home-page--posts-list {
      .posts-list--inner {
        overflow: hidden;

        .posts-list {
          display: flex;
          scroll-snap-type: x mandatory;
          overflow-x: scroll;
          margin: 0 -8px;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .post-item {
            scroll-snap-align: start;
            width: calc(100% / 2.2);
            max-width: calc(100% / 2.2);
            min-width: calc(100% / 2.2);
            padding: 0 8px;

            img {
              object-fit: cover;
              width: 100%;
              aspect-ratio: 1 / 1;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}
