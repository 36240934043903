footer#footer-info {
  &.footer-info--wrapper {
    background-color: #f2fafb;
    margin-left: -12px;
    margin-right: -12px;

    .footer-info--inner {
      padding: 30px 12px 15px;

      & > *:first-child {
        @media (max-width: $tablet-screen-max-width) {
          height: 50px !important;
          justify-content: flex-start;
        }
      }

      svg {
        // stroke: #fafdfd;
        // stroke-width: 3;
        // fill: #fafdfd;
        // stroke-dasharray: 1800;
        // stroke-dashoffset: 0;
        // animation: logo-render 5s ease-in-out infinite;

        @media (max-width: $tablet-screen-max-width) {
          height: 100%;
          width: auto;

          &:nth-child(2) {
            height: 200%;
          }
        }
      }
    }

    .footer-info--copyright {
      background-color: #dff3f6;
    }
  }
}

section#footer-action {
  &.footer-action--wrapper {
    position: fixed;
    bottom: 0;
    left: 50%;
    height: $footer-action-height;
    transform: translateX(-50%);
    z-index: 99;
    background-color: var(--color-white);
    width: $mobile-screen-max-width !important;
    box-shadow: 0 0 15px #888;

    @media (max-width: $tablet-screen-max-width) {
      width: 100vw !important;
    }

    a {
      span.relative {
        position: relative;
      }

      &.active,
      &:hover {
        span {
          color: var(--color-secondary-brand-blue);

          path {
            fill: var(--color-secondary-brand-blue) !important;
          }
        }
      }

      span.noti-point {
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        transform: translate(40%, -30%);
        border-radius: 100%;
        background-color: var(--color-red);
      }

      &:nth-child(3) {
        flex-grow: unset !important;
        // margin-bottom: 20px;
        position: relative;
        box-shadow: var(--box-shadow-input-black);
        background-color: rgb(238 245 245);
        padding-left: 10px;
        padding-right: 10px;
        width: 70px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;

        // &:after {
        //   content: "";
        //   position: absolute;
        //   top: 10px;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   background-color: rgba(var(--box-shadow-custom), 0.1);
        //   width: 150%;
        //   height: 100%;
        //   z-index: -1;
        //   border-radius: 50% 50% 50% 50% / 0% 0% 100% 100%;
        // }

        span {
          &:first-child {
            position: absolute;
            left: 50%;
            bottom: 10px;
            transform: translateX(-50%);
            background-color: var(--color-secondary-brand-blue);
            border-radius: 100%;
            width: 50px;
            height: 50px !important;
            box-shadow: 0 4px 6px #888;
            background-color: all 200ms linear;

            &:hover {
              background-color: var(--color-brand-blue);
            }

            @include flex(center, center);

            .noti-point {
              display: block !important;
              top: 50%;
              left: 50%;
              transform: translate(25%, -110%);
            }
          }

          &:last-child {
            display: none !important;
          }

          svg {
            path {
              fill: var(--color-white) !important;
            }
          }
        }
      }
    }
  }
}
