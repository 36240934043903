#blog-detail-page {
  &.blog-detail-page--wrapper {
    // animation: appear 0.75s;
  }

  .blogs-list--wrapper {
    margin: 0 -8px;

    .blog-item--wrapper {
      padding: 0 8px;

      .blog-item--img {
        img {
          border-radius: 5px;
        }
      }
    }
  }

  .blog-detail-page {
    &--inner {
      height: 100%;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;

      .banner--wrapper {
        margin-left: -12px;
        margin-right: -12px;
        position: relative;
        z-index: 1;

        img {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          object-fit: cover;
          height: 40vh;
        }
      }

      .box-content {
        position: relative;
        z-index: 2;
        background-color: var(--color-white);
        margin-top: -15vh;
        border-radius: 8px;
        padding: 18px 20px 50px;
        box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);
      }
    }
  }
}
