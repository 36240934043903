$textAligns: (left, center, right);

@each $val in $textAligns {
  .mk-text-#{$val} {
    text-align: $val;
  }
}

$textTransforms: (capitalize, lowercase, none, uppercase);

@each $val in $textTransforms {
  .mk-text-#{$val} {
    text-transform: $val;
  }
}

$truncates: (1, 2, 3, 4, 5, 6, 7, 8, 9);

@each $val in $truncates {
  .mk-truncate-#{$val} {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $val;
    overflow: hidden;
  }
}

.mk-fs-i {
  font-style: italic;
}

.mk-text-link {
  cursor: pointer;
  text-decoration: none;
}
