@mixin flex($align: flex-start, $justify: flex-start, $wrap: nowrap) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}

@mixin responsive-max($device) {
  @media only screen and (max-width: $device) {
    @content;
  }
}

@mixin responsive-min($device) {
  @media only screen and (min-width: $device) {
    @content;
  }
}
