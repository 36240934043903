/* Light Mode: Default */
:root {
  // #region : Brand
  --color-brand-blue: #08428c;
  --color-secondary-brand-blue: #3880bb;
  --color-brand-orange: #df7005;
  // #endregion
  // #region : Green (Success)
  --color-green: #009b7c;
  --color-secondary-green: #1dc49d;
  --color-subtle-green: #77dcc4;
  --color-light-green: #d2f3eb;
  --color-pale-green: #e9faf6;
  // #endregion
  // #region : Blue (Informative)
  --color-blue: #0ca1d0;
  --color-secondary-blue: #0db1e5;
  --color-subtle-blue: #57c9ed;
  --color-light-blue: #b0e3f3;
  --color-pale-blue: #dff7ff;
  // #endregion
  // #region : Red (Alert)
  --color-red: #e35a3c;
  --color-secondary-red: #e58069;
  --color-subtle-red: #f39f8c;
  --color-light-red: #ffc8bb;
  --color-pale-red: #ffe3dd;
  // #endregion
  // #region : Orange (Brand/Waring)
  --color-orange: #f89d26;
  --color-secondary-orange: #f9cd92;
  --color-subtle-orange: #f5c480;
  --color-light-orange: #fdeedb;
  --color-pale-orange: #fff7ed;
  // #endregion
  // #region : Black/White
  --color-black: #2f2f2e; // Title - Focus
  --color-secondary-black: #6f6f6d; // Paragraph - Filled
  --color-subtle-black: #b1b1b1; // Information - Placeholder
  --color-light-black: #dddddd; // Border - Separator
  --color-pale-black: #f3f3f3; // Background
  --color-white: #ffffff;
  // #endregion
  // #region : Box shadow
  --box-shadow-custom: 0, 0, 0;
  --box-shadow-custom-black: 121, 132, 165;
  --box-shadow-black: rgba(0, 0, 0, 0.15);
  --box-shadow-green: rgba(42, 187, 152, 0.4);
  --box-shadow-input-black: 0px 0px 5px rgba(168, 168, 168, 0.16);
  --box-shadow-input-brand: 0px 0px 5px rgba(8, 65, 140, 0.16);
  --box-shadow-input-green: 0px 0px 5px rgba(0, 155, 124, 0.16);
  --box-shadow-input-red: 0px 0px 5px rgba(227, 91, 60, 0.16);
  --box-shadow-input-orange: 0px 0px 5px rgba(248, 157, 37, 0.16);
  --box-shadow-input-orange-color: rgba(248, 157, 37, 0.25);
  // #endregion
}

/* Dark Mode */
[data-theme="dark"] {
}
