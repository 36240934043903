$mobile-screen-max-width: 425px;
$tablet-screen-max-width: 1024px;
$footer-action-height: 50px;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

html *::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

body {
  background-color: #efefef;
  text-rendering: optimizeSpeed;
  font-family: "Montserrat", sans-serif;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 300;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url(../../assets/images/background/background-1.png);
    background-repeat: repeat;
    z-index: -1;
  }
}

a {
  text-decoration: none;
  color: currentColor;
}

img {
  display: block;
  max-width: 100%;
}

#mobile-wrapper {
  position: relative;
  max-width: $mobile-screen-max-width !important;
  background-color: var(--color-white);
  height: 100vh;
  box-shadow: 0 0 15px #888;
  overflow-y: scroll;

  @media (max-width: $tablet-screen-max-width) {
    max-width: $tablet-screen-max-width !important;
  }

  .h-screen {
    height: calc(100vh - $footer-action-height);
  }
}

.form-control.is-invalid,
.form-control.is-valid,
.form-select.is-invalid,
.form-select.is-valid {
  background-image: none;
}

.input-custom-select {
  .input-group {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 31px;
      width: 20px;
      height: 80%;
      background-color: var(--color-white);
      z-index: 10;
      transform: translateY(-50%);
    }
  }

  &.disabled {
    .input-group {
      &:after {
        background-color: var(--color-light-black);
      }
    }
  }
}

.modal {
  .modal-dialog {
    max-width: calc($mobile-screen-max-width - 12px - 12px) !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
