.h-fit {
  height: fit-content;
}

.w-fit {
  width: fit-content;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.h-screen {
  height: 100vh;
}

.w-screen {
  width: 100vw;
}
