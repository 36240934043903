.user-layout {
  height: fit-content;

  & > * {
    &:first-child {
      margin-bottom: $footer-action-height;
    }
  }

  &.hide-footer-action {
    & > * {
      margin-bottom: 0;

      .h-screen {
        height: 100vh !important;
      }
    }
  }
}
