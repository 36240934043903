#login-page {
  &.login-page--wrapper {
    position: relative;
  }

  .login-page {
    &-first-screen--wrapper {
      position: absolute;
      z-index: 999999;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      background-color: var(--color-white);

      @include flex(center, center);
      flex-direction: column;

      &.hidden {
        left: -100%;
        opacity: 0;
        visibility: hidden;
        transition: all 1000ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    &--inner {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      padding-top: 90px;
      padding-bottom: 80px;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.5);

      &.show {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        transition: opacity 2000ms cubic-bezier(0.075, 0.82, 0.165, 1),
          visibility 2000ms cubic-bezier(0.075, 0.82, 0.165, 1),
          transform 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    &--header {
      margin-bottom: 50px;

      img {
        margin: 0 auto;
      }
    }

    &--form {
      flex-grow: 1;

      form {
        margin: 0 auto;
        max-width: 300px;
      }
    }

    &--footer {
      margin-top: 50px;
    }
  }
}
