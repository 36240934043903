.input-custom {
  width: 100%;
  max-width: 450px !important;
  text-align: left;

  @media (max-width: $tablet-screen-max-width) {
    max-width: unset !important;
  }

  label {
    margin-top: 16px !important;
    margin-bottom: 4px !important;
    color: var(--color-black) !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em !important;

    @include flex(center);
    gap: 5px;

    span.is-required {
      color: var(--color-red);
    }
  }

  input {
    padding: 0 35px 0 20px !important;
    height: 50px !important;
    border: 1px solid var(--color-light-black) !important;
    border-radius: 5px !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--color-white) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: var(--color-black) !important;

    &::-webkit-input-placeholder {
      color: var(--color-light-black) !important;
    }

    &::-moz-input-placeholder {
      color: var(--color-light-black) !important;
    }

    &.is-valid {
      border-color: var(--color-green) !important;
    }

    &.is-invalid {
      border-color: var(--color-red) !important;
    }

    &:focus {
      border-color: var(--color-brand-blue) !important;
    }

    &:disabled {
      color: var(--color-secondary-black) !important;
      background-color: var(--color-light-black) !important;
      cursor: no-drop !important;
    }
  }

  &-text {
    &.loading {
      .input-group {
        position: relative;

        input {
          padding-right: 30px !important;
        }

        span.input-loading-icon {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          cursor: pointer;
          z-index: 10;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  &-password {
    .input-group {
      position: relative;

      input {
        padding-right: 40px !important;
      }

      span.btn-show-password {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 10;

        svg {
          width: 20px;
          height: 20px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .form-error-msg {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: var(--color-red);
    margin-top: 5px;
  }

  &-checkbox {
    @include flex(center);
    flex-wrap: wrap;

    label {
      max-width: calc(100% - 18px - 8px);
      cursor: pointer;
      margin-left: 8px;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-subtle-black);
    }

    input {
      border: 1px solid var(--color-light-black) !important;
      border-radius: 5px !important;
      width: 18px !important;
      min-width: 18px !important;
      height: 18px !important;
      cursor: pointer !important;
      outline: none !important;
      box-shadow: none !important;
      margin: 0 !important;

      &:checked {
        background-color: var(--color-green);
      }
    }
  }

  &-date {
    input[type="tel"] {
      text-transform: uppercase;
      padding-left: 40px !important;
      position: relative;
      width: 100%;
    }

    .input-group {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        opacity: 1;
        display: block;
        background: url("../../../assets/images/iconography/Outline/Calendar2.svg")
          no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        border-width: thin;
      }
    }
  }

  &-text-area {
    textarea {
      border: 1px solid var(--color-light-black) !important;
      border-radius: 5px !important;
      outline: none !important;
      box-shadow: none !important;
      background-color: var(--color-white) !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 22px !important;
      color: var(--color-black) !important;
      resize: none !important;
      padding: 10px 20px 35px !important;

      &::-webkit-input-placeholder {
        font-size: 14px !important;
        color: var(--color-light-black) !important;
      }

      &::-moz-input-placeholder {
        font-weight: 400 !important;
        color: var(--color-light-black) !important;
      }

      &::placeholder {
        line-height: 22px !important;
        color: var(--color-light-black) !important;
      }

      &:focus,
      &.is-valid {
        border-color: var(--color-green) !important;
      }

      &.is-invalid {
        border-color: var(--color-red) !important;
      }

      &:disabled {
        color: var(--color-secondary-black) !important;
        background-color: var(--color-light-black) !important;
        cursor: no-drop !important;
      }
    }

    .text-area-wrapper {
      position: relative;

      .text-counter {
        position: absolute;
        z-index: 10;
        bottom: 10px;
        right: 20px;
      }
    }
  }

  &-select {
    select {
      height: 50px !important;
      border: 1px solid var(--color-light-black) !important;
      border-radius: 5px !important;
      outline: none !important;
      box-shadow: none !important;
      background-color: var(--color-white) !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      color: var(--color-black) !important;
      padding-right: 50px !important;

      &.color-placeholder {
        color: var(--color-light-black) !important;

        &:focus {
          color: var(--color-black) !important;
        }
      }

      &:focus,
      &.is-valid {
        border-color: var(--color-green) !important;
      }

      &.is-invalid {
        border-color: var(--color-red) !important;
      }

      &:disabled {
        color: var(--color-secondary-black) !important;
        background-color: var(--color-light-black) !important;
        cursor: no-drop !important;
      }
    }

    &.disabled {
      pointer-events: visible;
      cursor: no-drop;
    }
  }
}
