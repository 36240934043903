#voucher-detail-page {
  &.voucher-detail-page--wrapper {
    // animation: appear 0.75s;
    position: relative;

    .voucher-exchange-success-screen--wrapper {
      position: absolute;
      top: 0;
      left: 0;
      background-color: var(--color-white);
      width: 100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transform: scale(0.5);
      padding-bottom: 50px;

      &.show {
        z-index: 1;
        opacity: 1;
        visibility: visible;
        transform: scale(1);
        transition: opacity 2000ms cubic-bezier(0.075, 0.82, 0.165, 1),
          visibility 2000ms cubic-bezier(0.075, 0.82, 0.165, 1),
          transform 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      .box-content {
        background-color: var(--color-white);
        border-radius: 8px;
        box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);

        .voucher-item {
          &--img {
            img {
              width: 100%;
            }
          }

          &--content {
            padding: 18px 20px 50px;
          }
        }
      }

      button {
        @media (max-width: 499px) {
          padding: 0;
        }
      }
    }
  }

  .voucher-detail-page {
    &--inner {
      height: 100%;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;

      .banner--wrapper {
        margin-left: -12px;
        margin-right: -12px;
        position: relative;
        z-index: 1;

        img {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          object-fit: cover;
          height: 40vh;
        }
      }

      .box-content {
        position: relative;
        z-index: 2;
        background-color: var(--color-white);
        margin-top: -15vh;
        border-radius: 8px;
        padding: 18px 20px 50px;
        box-shadow: 0px 3px 10px var(--box-shadow-input-orange-color);
      }
    }
  }

  .pyro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
