#information-page {
  &.information-page--wrapper {
    // animation: appear 0.75s;
  }

  .information-page {
    &--inner {
      height: 100%;
      margin-left: -12px;
      margin-right: -12px;
      padding-left: 12px;
      padding-right: 12px;

      .avatar-wrapper {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        border-radius: 100%;
        border: 2px dashed var(--color-brand-orange);
        position: relative;
        padding: 3px;
        cursor: pointer;
        transition: all 200ms linear;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 100%;
          position: relative;
          z-index: 1;
        }

        span.btn-upload {
          position: absolute;
          bottom: 0;
          right: 0;
          background-color: var(--color-brand-orange);
          border-radius: 100%;
          width: 20px;
          height: 20px;
          transition: all 200ms linear;
          z-index: 2;

          @include flex(center, center);

          svg {
            width: 15px;
            height: 15px;
          }
        }

        &:hover {
          border-color: var(--color-secondary-brand-blue);

          span.btn-upload {
            background-color: var(--color-secondary-brand-blue);
            opacity: 0.8;
          }
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 3;
          cursor: pointer;
        }

        span.btn-loading {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 4;
          cursor: no-drop;
          background-color: rgba(0, 0, 0, 0.3);
          border-radius: 100%;

          @include flex(center, center);

          svg {
            animation: spin 4s linear infinite;
          }
        }
      }

      .information-wrapper {
        label {
          margin-top: 12px !important;
        }

        input,
        select {
          height: 40px !important;
        }

        .input-custom-select {
          .input-group {
            &:after {
              right: 33px;
            }
          }
        }
      }
    }
  }
}
